import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import axios from "axios";
import { addSowtoagency, getSowlist, removeSowtoagency } from "../utils";
import { react } from "@babel/types";
import { Avatar, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const AddAgencyProjectsModal = ({
  fullWidth,
  maxWidth,
  open,
  handleClose,
  data: { id },
}) => {
  const [projectData, setProjectData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [updateLoading,setUpdateLoading] = React.useState(false)
  const [isErr, setIsErr] = React.useState(false);

  const handleSwitchclicked = (data) =>{
    console.log(data,'data')
    const token = localStorage.getItem("token");
    const dataTokenloc = {
      headers: {
        "x-access-token": token,
      },
    };
    const postData = {
        sow_id: data.sow_id,
        id,
    }
    if(data.assigned=='yes'){
       
        axios.put(removeSowtoagency,postData,dataTokenloc)
        .then(res=>{console.log(res)
            setUpdateLoading(!updateLoading)
        })
        .catch(err=>console.error(err))
       
    }else{
       
        axios.put(addSowtoagency,postData,dataTokenloc)
        .then(res=>{console.log(res)
            setUpdateLoading(!updateLoading)
        })
        .catch(err=>console.error(err))
        
    }
   
  }
  useEffect(() => {
    const postData = {
      id,
    };
    const token = localStorage.getItem("token");
    const dataTokenloc = {
      headers: {
        "x-access-token": token,
      },
    };
    setIsLoading(true);
    axios
      .post(getSowlist, postData, dataTokenloc)
      .then((res) => {
        const data = res.data.sowlist.map((item, i) => ({ ...item, check: i }));
        console.log(data, "dataoneone");
        setProjectData(data);
        // setProjectData(res?.data?.sowlist);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsErr(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [updateLoading]);
  return  (
    <div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={'lg'}
        open={open}
        onClose={handleClose}
        style={{padding:'5px'}}
      >
        <DialogTitle style={{color:'#663595'}}>AddProjects</DialogTitle>
        <DialogContent></DialogContent>

        <div>
          <div className="row" >
            {projectData?.map((item) => (
              <div className="col-4">
                <Grid item key={item.sow_id} style={{ border:'1px solid black',margin:'20px',marginBottom:'10px',padding:'5px' ,borderRadius:'5px'}}>
                  <Grid
                    container
                    alignItems="center"
                    rowSpacing={2}
                    columnSpacing={3}
                    style={{ height: "70px" }}
                    // justifyContent='space-around'
                  >
                    <Grid item>
                      {" "}
                      <Avatar src={item.brand_logo} />
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction={"column"}
                        style={{ minWidth: "160px" }}
                      >
                        <Grid item style={{fontWeight:'700',fontSize:'15px'}}>{item.brand_name}</Grid>
                        <Grid item style={{fontWeight:'600',fontSize:'12px'}}>Sow ID : {item.sow_id}</Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      {" "}
                      <FormControlLabel
                        control={
                          <Android12Switch
                            defaultChecked={
                              item.assigned == "yes" ? true : false
                            }
                            onClick={()=>handleSwitchclicked(item)}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            ))}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AddAgencyProjectsModal;
