export const localRoute = {
  notFound: "/notfound",
  loginPage: "/login",
  otpPage: "/otp",
  dashboard: "/dashboard",
  leadDetails: "/lead-details",
  qcagents: "/qc-agents",
  agencies: "/agencies",
  stats: "/stats",
};
