import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import taskmoLogoQc from "../../assets/Images/taskmoqcLogo.svg";
import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import axios from "axios";
import { getUserinfo } from "../../utils";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import "./NavbarInside.css";
import Logout from "../../Logout/Logout";

export default function NavbarInside({ users }) {
  console.log(users, "loginusers");
  const location = useLocation();
  console.log(location.pathname, "pathnum");
  const navigate = useNavigate();
  console.log(location.pathname, "locationnavbar");
  const [loading, setLoading] = React.useState(false);
  const [isErr, setisErr] = React.useState(false);

  //handle logout
  const [openLogout, setOpenLogout] = React.useState(false);

  const handleClickOpenLogout = () => {
    setOpenLogout(true);
  };

  const handleCloseLogout = () => {
    setOpenLogout(false);
  };
  //swipable drawer list

  const handlesidebarclick = (i) => {
    if (i == 0) return "";
    if (i == 1) {
      navigate("/dashboard");
    }
    if (i == 2) {
      navigate("/qc-agents");
    }
    if (i == 3) {
      handleClickOpenLogout();
    }
    if (i == 4) {
      navigate("/agencies");
      handleClose();
    }
    if (i == 5) {
      navigate("/stats");
      handleClose();
    }
  };
  const list = () => (
    <Box sx={{ width: 250 }} role="presentation">
      <List>
        <ListItem>
          <ListItemButton
            onClick={() => {
              handleCloseDrawer();
              // handlesidebarclick(0);
            }}
          >
            <ListItemIcon>
              <img
                src={window.location.origin + "/Images/logopurple.svg"}
                alt="login"
                className="logo_css_side"
              />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>

        <ListItem>
          <ListItemButton
            onClick={() => {
              handleCloseDrawer();
              handlesidebarclick(1);
            }}
          >
            <ListItemIcon>
              <img
                src={window.location.origin + "/Images/dashicon.svg"}
                alt=""
              />
            </ListItemIcon>
            <ListItemText primary={"Dashboard"} />
          </ListItemButton>
        </ListItem>

        <ListItem>
          <ListItemButton
            onClick={() => {
              handleCloseDrawer();
              handlesidebarclick(5);
            }}
          >
            <ListItemIcon>
              <img
                src={window.location.origin + "/Images/stats_icon.svg"}
                alt=""
              />
            </ListItemIcon>
            <ListItemText primary={"Stats"} />
          </ListItemButton>
        </ListItem>

        {localStorage.getItem("type") == "root" ||
        localStorage.getItem("type") == "agency" ? (
          <ListItem>
            <ListItemButton
              onClick={() => {
                handleCloseDrawer();
                handlesidebarclick(2);
              }}
            >
              <ListItemIcon>
                <img
                  src={window.location.origin + "/Images/qc_agents_icon.svg"}
                  alt=""
                />
              </ListItemIcon>
              <ListItemText primary={"Qc Agents"} />
            </ListItemButton>
          </ListItem>
        ) : (
          ""
        )}

        {localStorage.getItem("type") == "root" ? (
          <ListItem>
            <ListItemButton
              onClick={() => {
                handleCloseDrawer();
                handlesidebarclick(4);
              }}
            >
              <ListItemIcon>
                <img
                  src={window.location.origin + "/Images/agent_management.svg"}
                  alt=""
                />
              </ListItemIcon>
              <ListItemText primary={"Agencies"} />
            </ListItemButton>
          </ListItem>
        ) : (
          ""
        )}
        <ListItem>
          <ListItemButton
            onClick={() => {
              handleCloseDrawer();
              handlesidebarclick(3);
            }}
          >
            <ListItemIcon>
              <img
                src={window.location.origin + "/Images/logout_icon.svg"}
                alt=""
              />
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
    </Box>
  );
  //swipable drawer
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const openDrawer = Boolean(anchorEl2);
  const handleClickOpenDrawer = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseDrawer = () => {
    setAnchorEl2(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [fullName, setFullName] = React.useState("");
  const projectName = localStorage.getItem("project");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    const dataTokenloc = {
      headers: {
        "x-access-token": token,
      },
    };
    setLoading(true);
    axios
      .get(getUserinfo, dataTokenloc)
      .then((res) => {
        console.log(res, "resresres");

        setFullName(res.data.userinfo.full_name);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // window.location.reload();
        setisErr(true);
      })
      .finally(() => setLoading(false));
  }, []);
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          // borderRadius: "70% / 0 0 100% 100%",
          background: "#673695",
          // height: "100px",
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleClickOpenDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems="center"
            // marginTop={"-20px"}
            // padding={"20px 60px"}
          >
            <Grid item>
              <img
                onClick={() => navigate("/dashboard")}
                src={taskmoLogoQc}
                alt="taskmologo"
                width="60px"
                height="60px"
                style={{ cursor: "pointer" }}
              />
            </Grid>
            <Grid item>
              <Typography variant="h5">
                {location.pathname == "/lead-details" ? (
                  <Grid
                    container
                    direction={"column"}
                    spacing={1}
                    justifyContent="center"
                    sx={{ marginTop: "10px" }}
                  >
                    <Grid item style={{ padding: "0px" }}>
                      <Typography align="center" fontWeight={500}>
                        {projectName?.charAt(0).toUpperCase() +
                          projectName?.slice(1)}
                      </Typography>
                    </Grid>
                    <Grid item style={{ padding: "0px" }}>
                      <Typography align="center" fontWeight={700}>
                        {localStorage.getItem("leadIdl")}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : location.pathname == "/qc-agents" ? (
                  "QC - Agents Table"
                ) : location.pathname == "/agencies" ? (
                  "Agencies Table"
                ) : (
                  "QC Dashboard"
                )}
              </Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                alignItems={"center"}
                spacing={1}
                justifyContent={"center"}
              >
                <Grid item>
                  <Avatar
                    sx={{ bgcolor: deepOrange[500], cursor: "pointer" }}
                    // onClick={handleClick}
                  >
                    {fullName.length > 0 ? fullName[0].toUpperCase() : ""}
                  </Avatar>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    {/* <MenuItem
                      onClick={() => {
                        handleClose();
                        localStorage.removeItem("token");
                        localStorage.removeItem("full_name");
                        window.location.reload();
                      }}
                    >
                      Logout
                    </MenuItem> */}
                  </Menu>
                </Grid>
                <Grid item> {fullName ?? ""}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer open={openDrawer} onClose={handleCloseDrawer}>
        {list()}
      </SwipeableDrawer>
      <Logout
        openLogout={openLogout}
        handleClickOpenLogout={handleClickOpenLogout}
        handleCloseLogout={handleCloseLogout}
      />
    </>
  );
}
