import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarInside from "../navbar/NavbarInside";
import "../../css/leadDetails.css";
import Zoom from "react-medium-image-zoom";
import { Controlled as ControlledZoom } from "react-medium-image-zoom";
import {
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import "react-medium-image-zoom/dist/styles.css";
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import axios from "axios";
import {
  clicktocall,
  getQcreasons,
  qcActionlog,
  qcSubmit,
  qc_image_script,
  qc_script,
} from "../../utils";
import { dataToken } from "../../config/token";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { type } from "@testing-library/user-event/dist/type";
import { Opacity } from "@mui/icons-material";
import ReactviewPdf from "./ReactviewPdf";
// import ReactPanZoom from "react-image-pan-zoom-rotate";
import { PDFViewer } from "react-view-pdf/lib";
import { Interweave } from "interweave";
import { SnackbarProvider } from "notistack";
import CallIcon from "@mui/icons-material/Call";
import { ReactPanZoom } from "./RaPack";

//React snackbar component
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LeadIdDetails = () => {
  const providerRef = React.useRef();
  const handleClickLeadSubmit = (varaintInfo, msg) => {
    providerRef.current.enqueueSnackbar(msg, {
      variant: varaintInfo,
    });
  };

  const navigate = useNavigate();
  const location = useLocation();
  const allleaddata = location.state;
  console.log(allleaddata, "allleaddata");
  let ot = allleaddata.ot.col
    .split(",")
    .splice(0, allleaddata.ot.col.split(",").length - 1);
  console.log(ot, "otcheck");
  const leaddata = location.state.ot.leads[0];
  const projectId = location.state.projectId;
  const leadId = location.state.leadId;
  const sowId = location.state.sow_id;
  const internalQc = location.state.internalQcStatus;
  const userType = localStorage.getItem("type");
  console.log(userType, "oie");
  console.log(internalQc, "hi");
  console.log(leaddata, "leaddd");
  const [reasonData, setReasonData] = React.useState([]);
  const [selectedReasonIndex, setSelectedReasonIndex] = React.useState(null);
  const [addRemark, setAddRemark] = React.useState("");
  const [img_zoom, setImg_zoom] = React.useState(false);
  const [img_url, setImg_url] = React.useState("");
  const [imgKEy, setImgKEy] = React.useState("");
  const [modalImgData, setModalImgData] = React.useState("");
  const [scriptData, setScriptData] = React.useState("");
  const [beforecallData, setBeforeCallData] = React.useState(undefined);
  const [aftercallData, setAfterCallData] = React.useState(undefined);
  const [intQcboolstatus, setIntQcboolstatus] = React.useState(false);
  const [inpreason, setInpReason] = React.useState("");
  const [callshow, setCallShow] = React.useState(false);

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("xl");

  const ref = useRef();

  console.log(img_url, "imggggg");
  const onZoomModalOpen = (sow, imgkey) => {
    const postData = {
      sow_id: sow,
      image_key: imgkey,
    };
    const token = localStorage.getItem("token");
    const dataTokenloc = {
      headers: {
        "x-access-token": token,
      },
    };
    setImgKEy(imgkey);
    axios
      .post(qc_image_script, postData, dataTokenloc)
      .then((res) => {
        setImg_zoom(true);
        setModalImgData(res?.data?.script_data?.script);
        console.log(res?.data?.script_data?.script, "resin");
      })
      .catch((err) => console.log(err, "err"));
  };
  const handleZoomclose = () => {
    setImg_zoom(false);
  };
  console.log(typeof selectedReasonIndex, "hmmmm");
  // console.log(leaddata, projectId, "leaddata");

  //snackbar inside
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleRemark = () => {
    console.log(addRemark, "addremark");
    let postData = {
      lead_id: leadId,
      action_comment: addRemark,
    };
    const token = localStorage.getItem("token");
    const dataTokenloc = {
      headers: {
        "x-access-token": token,
      },
    };
    axios
      .post(qcActionlog, postData, dataTokenloc)
      .then((res) => {
        console.log(res);
        handleClick();
      })
      .catch((err) => console.log(err));
  };

  const handleChangeReason = (e) => {
    console.log(e.target.innerHTML, "etarget");
    console.log(reasonData, "fasakreasondata", e.target.dataset);
    if (intQcboolstatus) {
      let [ot] = reasonData.filter((item) => item.reason == e.target.innerHTML);
      ref.current = ot;
      console.log(ot, "opsfasak");
      setInpReason(ot?.reason ?? "");
    } else {
      setInpReason(reasonData[e.target.dataset.optionIndex]?.reason ?? "");
    }
    if (e.target.innerHTML == "ALL THE DOCUMENTS ARE VERIFIED SUCCESSFULLY") {
      setCallShow(true);
    } else {
      setCallShow(false);
    }
    console.log(e.target.dataset.optionIndex, "eeee");
    e.target.dataset.optionIndex >= 0
      ? setSelectedReasonIndex(e.target.dataset.optionIndex)
      : setSelectedReasonIndex(null);
  };

  const clearReasonIndex = () => {
    setSelectedReasonIndex(null);
  };

  const handleSubmitReason = (d) => {
    console.log(d, "lead_id");
    console.log(reasonData[selectedReasonIndex], "fasak...");

    const postReason = {
      internal_qc_status: !intQcboolstatus
        ? reasonData[selectedReasonIndex]?.qc_status
        : ref.current?.qc_status,
      internal_qc_remark: !intQcboolstatus
        ? reasonData[selectedReasonIndex]?.reason
        : ref.current?.reason,
      lead_id: leadId,
      is_fake: !intQcboolstatus
        ? reasonData[selectedReasonIndex]?.is_fake == "fake"
          ? "1"
          : "0"
        : ref.current?.is_fake == "fake"
        ? "1"
        : "0",
    };
    clearReasonIndex();
    console.log(postReason, "testingpost");

    if (
      postReason.internal_qc_remark ==
      "ALL THE DOCUMENTS ARE VERIFIED SUCCESSFULLY"
    ) {
      clickToCall();
      setTimeout(() => {
        setInpReason("");
        setCallShow(false);
        setIntQcboolstatus(true);
      }, 10000);
    } else {
      axios
        .put(qcSubmit, postReason, dataToken)
        .then((res) => {
          console.log(res);
          navigate("/dashboard");
        })
        .catch((err) => console.log(err));
    }
  };

  const clickToCall = async () => {
    try {
      let data = {
        number: leaddata.customer_number,
        number_2: localStorage.getItem("userNumber"),
        lead_id: localStorage.getItem("leadIdl"),
        qc_admin_id: localStorage.getItem("userIdLogin"),
      };
      let res = await axios.post(clicktocall, data);
      console.log(res);
      handleClickLeadSubmit("success", "call initiated successfully");
    } catch (error) {
      console.log(error, "error");
      handleClickLeadSubmit("error", "something went wrong!");
    }
  };

  const getScript = () => {
    let postData = {
      sow_id: allleaddata?.sow_id ?? 0,
    };
    const token = localStorage.getItem("token");
    const dataTokenloc = {
      headers: {
        "x-access-token": token,
      },
    };
    axios
      .post(qc_script, postData, dataTokenloc)
      .then((res) => {
        setScriptData(res?.data["script_data"]);
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    const postData = {
      project_id: projectId,
    };
    const token = localStorage.getItem("token");
    const dataTokenloc = {
      headers: {
        "x-access-token": token,
      },
    };
    console.log(token, "tokenfasakfasak");
    axios
      .post(getQcreasons, postData, dataTokenloc)
      .then((res) => {
        console.log(res.data.reasons, "response");
        const ot = res.data.reasons;
        console.log(ot, "ottt");
        const mapd = ot.map((item) => ({ ...item, label: item.reason }));
        setReasonData(mapd);
        const bcd = mapd.filter((item) => item.after_call == "no");
        setBeforeCallData(bcd);

        const acd = mapd.filter((item) => item.after_call == "yes");
        setAfterCallData(acd);
      })
      .catch((err) => console.log(err));

    getScript();
  }, []);

  return (
    <SnackbarProvider
      maxSnack={3}
      ref={providerRef}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <div>
        <NavbarInside />
        <div style={{ display: "flex", gap: "20px" }}>
          <div style={{ zIndex: "100", width: "60%" }}>
            {/* <Paper
            style={{ border: "none", zIndex: "100" }}
            sx={{
              marginTop: "calc(1% + 48px)",
              position: "fixed",
              top: 0,
              width: "50%",
              border: "none",
              height: "100px",
              boxShadow: "none",
            }}
            component="footer"
          ></Paper> */}
            <Grid Container style={{ alignItems: "normal", paddingLeft: "1%" }}>
              <Grid item>
                <div
                  // className="tab-container"
                  // style={{ marginBottom: "200px", marginTop: "160px" }}
                  // style={{ marginBottom: "200px" }}

                  style={{
                    marginTop: "60px",
                    height: "100vh",
                    overflowX: "scroll",
                    marginBottom: "100px",
                  }}
                >
                  {allleaddata.ot.col
                    .split(",")
                    .splice(0, allleaddata.ot.col.split(",").length - 1)
                    .map((col, index) => {
                      return col.split("_").join(" ") != "customer number" &&
                        col
                          .split("_")
                          .join(" ")
                          .match(/sub leads.*/)?.index !== 0 ? (
                        <div className="verifyRowedit">
                          <div className="col_flex">
                            <div className="numberTitle mb-10">
                              {col.split("_").join(" ")}
                              {
                                col
                                  .split("_")
                                  .join(" ")
                                  .match(/sub leads.*/)?.index
                              }
                            </div>
                            <div>
                              <span style={{ textTransform: "uppercase" }}>
                                {leaddata[`${col}`]}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      );
                    })}
                  {allleaddata.ot.col
                    .split(",")
                    .splice(0, allleaddata.ot.col.split(",").length - 1)
                    .map((col, index) => {
                      return col
                        .split("_")
                        .join(" ")
                        .match(/sub leads.*/)?.index === 0 ? (
                        <div className="verifyRowedit">
                          <div className="col_flex">
                            <div className="numberTitle mb-10">
                              {col.split(".")[1].split("_").join(" ")}
                            </div>
                            <div>
                              <span style={{ textTransform: "uppercase" }}>
                                {leaddata[`${col.split(".")[1]}`]}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      );
                    })}
                  {allleaddata.ot.view
                    .split(",")
                    .splice(0, allleaddata.ot.view.split(",").length - 1)
                    .map((col, index) => {
                      console.log(col, index, "fasak");
                      return col
                        .split("_")
                        .join(" ")
                        .match(/sub leads.*/)?.index !== 0 ? (
                        <div className="verifyRowedit">
                          <div>{col.split("_").join(" ")}</div>
                          <div className="thumbnail-container">
                            <div className="thumb1">
                              <div>
                                <img
                                  src={
                                    leaddata[`${col}`]?.length > 0
                                      ? leaddata[`${col}`]
                                      : "/images/unavailable.jpeg"
                                  }
                                  alt="shop-front"
                                  onError={(e) => {
                                    e.target.src = `${window.location.origin}/Images/unavailable.jpeg`;
                                  }}
                                  className="thumbnail"
                                  onClick={() => {
                                    console.log(sowId, "clicked", col);
                                    onZoomModalOpen(sowId, col);
                                    setImg_url(leaddata[`${col}`]);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      );
                    })}

                  {allleaddata.ot.view
                    .split(",")
                    .splice(0, allleaddata.ot.view.split(",").length - 1)
                    .map((col, index) => {
                      console.log(col, index, "fasak");
                      return col
                        .split("_")
                        .join(" ")
                        .match(/sub leads.*/)?.index === 0 ? (
                        <div className="verifyRowedit">
                          <div>{col.split(".")[1].split("_").join(" ")}</div>
                          <div className="thumbnail-container">
                            <div className="thumb1">
                              <div>
                                <img
                                  src={
                                    leaddata[`${col.split(".")[1]}`]?.length > 0
                                      ? leaddata[`${col.split(".")[1]}`]
                                      : "/images/unavailable.jpeg"
                                  }
                                  alt="shop-front"
                                  onError={(e) => {
                                    e.target.src = `${window.location.origin}/Images/unavailable.jpeg`;
                                  }}
                                  className="thumbnail"
                                  onClick={() => {
                                    console.log(sowId, "clicked", col);
                                    onZoomModalOpen(sowId, col);
                                    setImg_url(
                                      leaddata[`${col.split(".")[1]}`]
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      );
                    })}

                  {/* <Grid
                  marginTop={"20px"}
                  container
                  className="verifyRowedit"
                  direction="column"
                  spacing={2}
                  style={{ minWidth: "723px", marginLeft: "2px" }}
                >
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems={"center"}
                    >
                      <Grid item>Lead Remarks</Grid>
                      <Grid item>
                        <Button
                          onClick={handleRemark}
                          style={{ cursor: "pointer" }}
                          variant="contained"
                          disabled={addRemark == ""}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <TextareaAutosize
                      onChange={(e) => setAddRemark(e.target.value)}
                      minRows={5}
                      aria-label="empty textarea"
                      placeholder="Enter Remarks"
                      style={{
                        width: 650,
                        fontSize: "18px",
                        fontFamily: "Nunito",
                      }}
                    />
                  </Grid>
                </Grid> */}
                </div>
              </Grid>
            </Grid>
            {/* <Paper
              elevation={1000}
              style={{ border: "none", zIndex: "100" }}
              sx={{
                marginTop: "calc(10% + 60px)",
                position: "fixed",
                bottom: 0,
                // width: "60%",
                border: "none",
                height: "100px",
                boxShadow: "none",
                marginLeft: "10px",
              }}
              component="footer"
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Grid
                  container
                  justifyContent={"center"}
                  alignItems="center"
                  spacing={2}
                  direction="column"
                  padding="20px 20px 20px 2px"
                >
                  <Grid
                    container
                    alignItems={"stretch"}
                    justifyContent="center"
                    spacing={8}
                    style={{ display: userType === "viewer" ? "none" : "" }}
                  >
                    <Grid item>
                      <Grid
                        container
                        style={{
                          background: "#fff",
                          gap: "50px",
                          padding: "20px",
                          // width: "123%",
                          marginBottom: "-30px",
                          borderRadius: "10px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                        }}
                      >
                        <Grid item>
                          <Autocomplete
                            inputValue={inpreason}
                            disablePortal
                            id="combo-box-demo"
                            // options={reasonData}
                            options={
                              intQcboolstatus ? aftercallData : beforecallData
                            }
                            sx={{ width: 590 }}
                            onChange={handleChangeReason}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={inpreason}
                                label=" Select Reason"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item>
                          <Button
                            id="button"
                            // disabled={true}
                            disabled={
                              selectedReasonIndex !== null && internalQc !== 0
                                ? false
                                : true
                            }
                            style={{
                              // opacity: "0.5",
                              opacity:
                                selectedReasonIndex !== null && internalQc !== 0
                                  ? "1"
                                  : "0.5",
                              color: "#663595",
                              borderColor: "#663595",
                              width: "150px",
                              height: "55px",
                            }}
                            variant="outlined"
                            onClick={handleSubmitReason}
                            startIcon={callshow ? <CallIcon /> : ""}
                          >
                            {!callshow ? "Submit" : "Call"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Paper> */}
            <div
              style={{
                position: "fixed",
                bottom: 15,
                width: "60%",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Grid
                  container
                  justifyContent={"center"}
                  alignItems="center"
                  spacing={2}
                  direction="column"
                  padding="20px 20px 20px 2px"
                >
                  <Grid
                    container
                    alignItems={"stretch"}
                    justifyContent="center"
                    spacing={8}
                    style={{ display: userType === "viewer" ? "none" : "" }}
                  >
                    <Grid item>
                      <Grid
                        container
                        style={{
                          background: "#fff",
                          minWidth: "800px",
                          gap: "50px",
                          padding: "20px",
                          // width: "123%",
                          marginBottom: "-30px",
                          borderRadius: "10px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                        }}
                      >
                        <Grid item style={{ width: "70%" }}>
                          <Autocomplete
                            inputValue={inpreason}
                            disablePortal
                            fullWidth
                            id="combo-box-demo"
                            // options={reasonData}
                            options={
                              intQcboolstatus ? aftercallData : beforecallData
                            }
                            onChange={handleChangeReason}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                {...params}
                                value={inpreason}
                                label=" Select Reason"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item>
                          <Button
                            id="button"
                            // disabled={true}
                            disabled={
                              selectedReasonIndex !== null && internalQc !== 0
                                ? false
                                : true
                            }
                            style={{
                              // opacity: "0.5",
                              opacity:
                                selectedReasonIndex !== null && internalQc !== 0
                                  ? "1"
                                  : "0.5",
                              color: "#663595",
                              borderColor: "#663595",
                              width: "150px",
                              height: "55px",
                            }}
                            variant="outlined"
                            onClick={handleSubmitReason}
                            startIcon={callshow ? <CallIcon /> : ""}
                          >
                            {!callshow ? "Submit" : "Call"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>

          <div
            style={{
              overflowX: "scroll",
              marginTop: "60px",
              height: "100vh",
              width: "40%",
            }}
          >
            <Interweave content={scriptData} />
          </div>
        </div>

        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Remark added Successfully!
          </Alert>
        </Snackbar>

        {img_zoom && (
          <Dialog
            disableEscapeKeyDown
            open={img_zoom}
            onClose={handleZoomclose}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
          >
            <AppBar
              sx={{ position: "relative", bgcolor: "#fff", boxShadow: "none" }}
            >
              <Toolbar sx={{ justifyContent: "flex-end" }}>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleZoomclose}
                  aria-label="close"
                >
                  <CloseIcon sx={{ color: "grey" }} />
                </IconButton>
              </Toolbar>
            </AppBar>
            <h5
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <h5>{imgKEy.split("_").join(" ") ?? ""}</h5>
              <div>{""}</div>
            </h5>
            <div
              style={{
                display: "flex",
                height: "100vh",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              {/* <div
              style={{
                width: 450,
                height: 450,
                position: "relative",
                overflow: "scroll",
              }}
            >
              <ReactPanZoom
                alt="image"
                image={
                  img_url
                    ? img_url
                    : `${window.location.origin}/Images/unavailable.jpeg`
                }
              />
            </div> */}
              <div
                style={{
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <ReactPanZoom
                  alt="cool image"
                  image={
                    img_url
                      ? img_url
                      : `${window.location.origin}/Images/unavailable.jpeg`
                  }
                />
              </div>

              <div
                style={{
                  width: "40%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "100px",
                }}
              >
                <div
                  style={{
                    marginTop: "-200px",
                    color: "#663595",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  Image Description
                </div>
                <Interweave content={modalImgData} />
              </div>
            </div>
          </Dialog>
        )}
      </div>
    </SnackbarProvider>
  );
};

export default LeadIdDetails;
