import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import taskmoLogoQc from "../../assets/Images/taskmoqcLogo.svg";
import { Grid } from "@mui/material";
export default function Navbar() {
  return (
    <AppBar
      position="fixed"
      sx={{
        // borderRadius: "70% / 0 0 100% 100%",
        background: "#673695",
        // height: "0px",
      }}
    >
      <Toolbar>
        <Grid
          container
          justifyContent={"center"}
          alignItems="center"
          // padding={"20px"}
        >
          <Grid item>
            <img
              src={taskmoLogoQc}
              alt="taskmologo"
              width="60px"
              height="60px"
            />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
