import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Table,
  TextField,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { dataToken } from "../../config/token";
import {
  getLeadSearch,
  getLeadStatus,
  lead_data,
  lead_request,
} from "../../utils";
import Navbar from "../navbar/Navbar";
import NavbarInside from "../navbar/NavbarInside";
import Tableone from "../Table/Tableone";
import SearchIcon from "@mui/icons-material/Search";
import "./../../css/dashboard.css";
import LoadingButton from "@mui/lab/LoadingButton";

import { useLocation, useNavigate } from "react-router-dom";

import { SnackbarProvider } from "notistack";

//mui tabs
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const StatisticComp = ({ threecardData }) => {
  return (
    <div>
      <Grid
        container
        // className="dashboardCardHeader"
        sx={{ width: "100%", m: "0px" }}
        spacing={10}
      >
        <Grid item sm={4} sx={{ pl: "40px !important", pr: "40px !important" }}>
          <Grid container sx={{ paddingLeft: "0px" }}>
            <Grid xs={12} item className="cardInsideDashboard">
              <Grid xs={12} item className="cardInsideDashboard">
                <Grid
                  container
                  direction={"column"}
                  spacing={3}
                  alignItems="center"
                  justifyContent={"center"}
                  textAlign="center"
                  color={"#673695"}
                  fontWeight="600"
                >
                  <Grid item xs={4}>
                    Total leads
                  </Grid>
                  <Grid item fontWeight={"800"}>
                    {threecardData?.total_leads ?? 0}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={4} sx={{ pl: "40px !important", pr: "40px !important" }}>
          <Grid container>
            <Grid item xs={12} className="cardInsideDashboard">
              <Grid item xs={12} className="cardInsideDashboard">
                <Grid
                  container
                  direction={"column"}
                  spacing={3}
                  alignItems="center"
                  justifyContent={"center"}
                  textAlign="center"
                  color={"green"}
                  fontWeight="600"
                  sx={{ width: "100%" }}
                >
                  <Grid item sm={4}>
                    Approved leads
                  </Grid>
                  <Grid item fontWeight={"800"}>
                    {threecardData.approved_leads ?? 0}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={4} sx={{ pl: "40px !important", pr: "40px !important" }}>
          <Grid container>
            <Grid item xs={12} className="cardInsideDashboard">
              <Grid
                container
                direction={"column"}
                spacing={3}
                alignItems="center"
                justifyContent={"center"}
                textAlign="center"
                color={"red"}
                fontWeight="600"
              >
                <Grid item xs={4}>
                  Rejected leads
                </Grid>
                <Grid item fontWeight={"800"}>
                  {threecardData?.rejected_leads ?? 0}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export const Dashboard = () => {
  const providerRef = React.useRef();
  const handleClick = (varaintInfo, msg) => {
    providerRef.current.enqueueSnackbar(msg, {
      variant: varaintInfo,
    });
  };

  let location = useLocation();

  let uers = location?.state?.users;

  console.log(uers, "location123");
  const [calltableOne, setCalltableOne] = React.useState(false);

  const [searchData, setSearchData] = React.useState("");
  const [userType, setUserType] = React.useState(localStorage.getItem("type"));
  const [loaderReq, setLoaderReq] = React.useState(false);

  //mui tabs

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const handleRequestLead = () => {
    setLoaderReq(true);
    const token = localStorage.getItem("token");
    const dataTokenloc = {
      headers: {
        "x-access-token": token,
      },
    };
    axios
      .put(lead_request, "", dataTokenloc)
      .then((res) => {
        console.log(res);
        setCalltableOne(!calltableOne);
        setLoaderReq(false);
        handleClick("success", "Leads added to bucked successfully");
      })
      .catch((err) => {
        console.log(err);
        handleClick("error", "Please finish the Pending Leads!");
      })
      .finally(() => {
        setLoaderReq(false);
      });
  };

  const handleClickSearch = () => {
    const postData = {
      lead_id: searchData,
    };
    const token = localStorage.getItem("token");
    const dataTokenloc = {
      headers: {
        "x-access-token": token,
      },
    };
    axios
      .post(getLeadSearch, postData, dataTokenloc)
      .then((res) => {
        console.log(res.data.leads, "resresres");
        if (res?.data?.leads?.length > 0) {
          let otdata = res.data.leads[0];
          console.log(otdata, "otdataotdata");
          let api =
            lead_data + otdata.qc_table_data + "&lead_id=" + otdata.lead_id;
          const token = localStorage.getItem("token");
          const dataTokenloc = {
            headers: {
              "x-access-token": token,
            },
          };
          axios
            .get(api, dataTokenloc)
            .then((res) => {
              // console.log(res);
              let ot = res.data;
              console.log(ot, "otdash");
              navigate("/lead-details", {
                state: {
                  ot,
                  projectId: otdata.project_id,
                  leadId: otdata.lead_id,
                },
              });
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err, "err"));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      searchData.length === 6 && handleClickSearch();
    }
  };

  return (
    <SnackbarProvider
      maxSnack={3}
      ref={providerRef}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <div>
        <NavbarInside users={uers} />

        <Box sx={{ width: "100%", marginTop: "80px" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              centered
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="My bucket" {...a11yProps(0)} />
              <Tab label="FollowUP" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Grid
              container
              spacing={6}
              direction="column"
              justifyContent={"center"}
              alignItems="center"
              style={{
                marginLeft: "0px",
                width: "100%",
                padding: "0px",
              }}
            >
              {
                <Grid
                  container
                  item
                  sx={{ width: "100%", paddingLeft: "0px !important" }}
                  justifyContent="center"
                ></Grid>
              }
              <Grid item>
                <Grid container>
                  <Grid item>
                    {userType == "qc" ? (
                      <>
                        <LoadingButton
                          style={{
                            width: "300px",
                            height: "40px",
                            borderColor: loaderReq ? "#673695" : "#673695",
                            backgroundColor: loaderReq ? "#673695" : "#673695",
                            color: "#fff",
                            marginBottom: "50px",
                          }}
                          size="small"
                          onClick={handleRequestLead}
                          loading={loaderReq}
                          loadingPosition="end"
                          variant="contained"
                        >
                          <span>Request For Leads</span>
                        </LoadingButton>
                      </>
                    ) : (
                      ""
                    )}
                    {userType != "" && userType !== "qc" ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          margin: " 20px 0 20px 0",
                        }}
                      >
                        <div>
                          <TextField
                            onKeyDown={handleKeyDown}
                            size="small"
                            type="number"
                            placeholder="Search Lead Id"
                            style={{
                              backgroundColor: "#f0f0f0",
                              fontFamily: "Nunito",
                              border: "none",
                              boxShadow: "none",
                              borderRadius: "4px",
                              width: "300px",
                            }}
                            sx={{
                              "& fieldset": { border: "none" },
                            }}
                            id="name"
                            variant="outlined"
                            name="merchant_number"
                            inputProps={{
                              inputmode: "numeric",
                              pattern: "[0-9]*",
                            }}
                            fullWidth
                            onInput={(e) => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 6);
                            }}
                            onChange={(e) => setSearchData(e.target.value)}
                          />
                        </div>
                        <div style={{ position: "relative", right: "60px" }}>
                          <Button
                            onClick={handleClickSearch}
                            disabled={searchData.length !== 6}
                          >
                            <SearchIcon />
                          </Button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {
              <Tableone
                dataval={0}
                calltableOne={calltableOne}
                sestCalltableOne={setCalltableOne}
              />
            }
          </TabPanel>
          <TabPanel value={value} index={1}>
            {
              <Tableone
                dataval={1}
                calltableOne={calltableOne}
                sestCalltableOne={setCalltableOne}
              />
            }
          </TabPanel>
        </Box>
      </div>
    </SnackbarProvider>
  );
};
