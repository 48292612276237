const token = localStorage.getItem("token");
const token_type = localStorage.getItem("type");
const fin_id = localStorage.getItem("finance_id");

const indianNumbers = (num, len) => {
  return Number(num).toLocaleString("en-IN", {
    maximumFractionDigits: len,
  });
};
const dataToken = {
  headers: {
    "x-access-token": token,
  },
};

export { dataToken, token, token_type, indianNumbers, fin_id };
