import { Button, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { getotp, verifyotp } from "../../utils";
import Navbar from "../navbar/Navbar";
import otp from "../../assets/Images/otp.svg";
import "../../css/Otp.css";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";

const Otp = () => {
  const [err, setErr] = useState(false);
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const {
    state: {
      data: { mobile_number },
    },
  } = useLocation();
  console.log(mobile_number, "location");

  const handleChangeInput = (code) => setCode(code);

  const handleSubmit = (e) => {
    e.preventDefault();
    const postData = {
      mobile_number,
      otp: code,
    };

    setErr(false);
    axios
      .post(verifyotp, postData)
      .then((res) => {
        console.log(res, "checkres");
        if (res.data.error) {
          setErr(true);
        } else {
          localStorage.setItem("count", 1);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("userNumber", res?.data?.users?.number);
          localStorage.setItem("userIdLogin", res?.data?.users?.id);
          //changed user_type to type
          localStorage.setItem("type", res.data?.users?.type);
          console.log(res.data, "resdtadata");
          navigate("/dashboard", { state: res.data });
        }
      })
      .catch((err) => {
        console.log(err.message);
        setErr(true);
      });
  };

  return (
    <div>
      <Navbar />
      <Grid container justifyContent={"center"} className="mainGridLogin">
        <Grid item xs={12} md={6}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              direction="column"
              justifyContent={"center"}
            >
              <Grid item xs={12} md={6}>
                <img src={otp} alt="otp" class="mainImagedim" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h5" gutterBottom>
                  Quality Check Dashboard
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  className="mobileNumberHeading"
                >
                  Verifying your number
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <label id="mbNumber">
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Enter the four digit otp share to your number
                      </Typography>
                    </label>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <OtpInput
                      value={code}
                      onChange={handleChangeInput}
                      numInputs={4}
                      separator={<span style={{ width: "12px" }}></span>}
                      isInputNum={true}
                      shouldAutoFocus={true}
                      inputStyle={{
                        border: "1px solid #673695",
                        borderRadius: "4px",
                        width: "55px",
                        height: "30px",
                        fontSize: "12px",
                        color: "#000",
                        fontWeight: "400",
                        caretColor: "blue",
                      }}
                      focusStyle={{
                        border: "1px solid #CFD3DB",
                        outline: "none",
                      }}
                    />
                  </Grid>
                  <Grid item>{err && <div>otp is wrong</div>}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  type="submit"
                  variant="contained"
                  className="buttonLogin"
                >
                  submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default Otp;
