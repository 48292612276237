import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Agencies from "../pages/Agencies";
import { Dashboard } from "../pages/dashboard/Dashboard";
import LeadIdDetails from "../pages/leadidDetails/LeadIdDetails";
import Stats from "../pages/leadidDetails/stats/Stats";
import Login from "../pages/login/Login";
import Otp from "../pages/login/Otp";
import NotFound from "../pages/notfound/NotFound";
import QcAgents from "../pages/QcAgents";
import { PrivateRoute } from "../privateRoute/PrivateRoute";
import { PrivateRoute2 } from "../privateRoute/PrivateRoute2";
import { localRoute } from "./localRoutes";

const RouterComponent = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path={localRoute.loginPage} element={<PrivateRoute />}>
          <Route path={localRoute.loginPage} element={<Login />} />
        </Route>
        <Route path={localRoute.otpPage} element={<PrivateRoute />}>
          <Route path={localRoute.otpPage} element={<Otp />} />
        </Route>
        <Route path={localRoute.notFound} element={<NotFound />} />
        <Route path={localRoute.dashboard} element={<PrivateRoute2 />}>
          <Route path={localRoute.dashboard} element={<Dashboard />} />
        </Route>
        <Route path={localRoute.leadDetails} element={<PrivateRoute2 />}>
          <Route path={localRoute.leadDetails} element={<LeadIdDetails />} />
        </Route>

        <Route path={localRoute.qcagents} element={<PrivateRoute2 />}>
          <Route path={localRoute.qcagents} element={<QcAgents />} />
        </Route>

        <Route path={localRoute.agencies} element={<PrivateRoute2 />}>
          <Route path={localRoute.agencies} element={<Agencies />} />
        </Route>

        <Route path={localRoute.stats} element={<PrivateRoute2 />}>
          <Route path={localRoute.stats} element={<Stats />} />
        </Route>
      </Routes>
    </div>
  );
};

export default RouterComponent;
