import { Button, Grid, Menu, MenuItem } from "@mui/material";
import axios from "axios";
import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { getAgencies, getQcagents } from "../utils";
import NavbarInside from "./navbar/NavbarInside";
import Chip from "@mui/material/Chip";
import "./../css/QcAgents.css";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import EditIcon from "@mui/icons-material/Edit";
import { FilterAlt } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import AddQcAgentModal from "../Modal/AddQcAgentModal";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

//adding snackbar component => notifications
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import EditAgencyModal from "../Modal/EditAgencyModal";
import AddAgencyModal from "../Modal/AddAgencyModal";
import AddAgencyProjectsModal from "../Modal/AddAgencyProjectsModal";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

//snackbar
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Agencies = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isErr, setIsErr] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [statusApiCall, setStatusApiCall] = React.useState("active");
  const [openeditModal, setOpenEditModal] = React.useState(false);
  const [editData, setEditData] = React.useState([]);
  const [updatedData, setUpdatedData] = React.useState(false);

  const [openAddModal, setOpenAddModal] = React.useState(false);
  //for projects
  const [showAgencyProjects, setShowAgencyProjects] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [rowData, setRowData] = React.useState({});

  const [openNotSnkAdd, setOpenNotSnkAdd] = React.useState(false);

  const handleClickNotSnkAdd = () => {
    setOpenNotSnkAdd(true);
  };

  const handleCloseNotSnkAdd = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotSnkAdd(false);
  };

  const [openNotSnkAddErr, setOpenNotSnkAddErr] = React.useState(false);

  const handleClickNotSnkAddErr = () => {
    setOpenNotSnkAddErr(true);
  };

  const handleCloseNotSnkAddErr = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotSnkAddErr(false);
  };

  const handleClickOpenAdd = () => {
    setOpenAddModal(true);
  };

  const handleCloseAdd = () => {
    setOpenAddModal(false);
  };

  const onOpenEditModal = (d) => {
    setEditData(d);
    setOpenEditModal(true);
  };

  const onCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorElAction, setAnchorElAction] = React.useState(null);
  const openAction = Boolean(anchorElAction);
  const handleClickAction = (event, d) => {
    setAnchorElAction(event.currentTarget);
    setRowData(d);
  };
  const handleCloseAction = () => {
    setAnchorElAction(null);
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: "50px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "30px", // override the cell padding for head cells
        // paddingRight: "8px",
      },
    },
    // cells: {
    //   style: {
    //     paddingLeft: "8px", // override the cell padding for data cells
    //     paddingRight: "8px",
    //   },
    // },
  };

  //addprojects
  const handleClickOpenProjects = (d) => {
    setShowAgencyProjects(true);
  };

  const handleCloseProjects = () => {
    setShowAgencyProjects(false);
  };

  const conditionalRowStyles = [
    {
      when: (row) => Number(row.slno) % 2 === 0,
      style: {
        background: "#f7f7f7",
        border: "none",
        color: "black",
        cursor: "pointer",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) => Number(row.slno) % 2 !== 0,
      style: {
        background: "#ffffff",
        border: "none",
        color: "black",
        cursor: "pointer",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const columns = [
    // {
    //   name: "Id:",
    //   width: "100px",
    //   center: true,
    //   cell: (d) => d.id,

    //   style: {
    //     paddingLeft: "30px",
    //   },
    // },
    {
      name: "Name",
      // width: "100px",
      center: true,
      selector: (row) => row.full_name,

      style: {
        paddingLeft: "30px",
      },
    },

    {
      name: "Number",
      selector: (row) => row.number,
      // width: "130px",
      center: true,
    },
    {
      name: "Email",
      selector: (row) => row.email_id,
      center: true,
    },
    // {
    //   name: "UserType",
    //   selector: (row) => row.type,
    //   cell: (d) => <div>{d.type}</div>,
    //   center: true,
    // },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (d) => (
        <div>
          {d.status == "active" ? (
            <Chip label="active" color="success" />
          ) : (
            <Chip label="inactive" color="error" />
          )}
        </div>
      ),

      center: true,
    },
    {
      name: "Action",
      cell: (d) => (
        // <div onClick={() => {onOpenEditModal(d)}}>
        <>
          <div
            onClick={(e) => {
              handleClickAction(e, d);
            }}
          >
            <MoreVertIcon />
          </div>
          <Menu
            id="basic-menu2"
            anchorEl={anchorElAction}
            open={openAction}
            onClose={handleCloseAction}
            MenuListProps={{
              "aria-labelledby": "basic-button2",
            }}
          >
            <MenuItem
              onClick={() => {
                onOpenEditModal(d);
                handleCloseAction();
              }}
            >
              <FeatherIcon
                icon="edit"
                style={{ color: "#663595", cursor: "pointer" }}
              />
              &nbsp;Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCloseAction();
                handleClickOpenProjects();
              }}
            >
              <AddCircleOutlineIcon style={{ color: "#663595" }} />{" "}
              &nbsp;Projects
            </MenuItem>
          </Menu>
        </>
      ),
      center: true,
    },
  ];

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    const dataTokenloc = {
      headers: {
        "x-access-token": token,
      },
    };
    const postData = {
      type: statusApiCall,
    };
    setIsLoading(true);
    axios
      .post(getAgencies, postData, dataTokenloc)
      .then((res) => {
        setIsLoading(false);
        // setData(res.data.qcagents);
        const ot = res.data.qcagents.map((item, index) => ({
          ...item,
          slno: index,
        }));
        setData(ot);
      })
      .catch((err) => {
        console.log(err, "err");
        setIsErr(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [statusApiCall, updatedData]);

  const dataTable = {
    columns: columns,
    data: data,
  };
  return isLoading ? (
    <div>
      {" "}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    </div>
  ) : isErr ? (
    <div>something went wrong</div>
  ) : (
    <div>
      <NavbarInside />
      <div
        style={{
          marginTop: "100px",
          display: "flex",
          // justifyContent: "center",
        }}
      >
        <Grid
          container
          // justifyContent="center"
          // alignContent={"center"}
          // alignItems="center"
          padding={"10px"}
        >
          <Grid item xs={12} md={12}>
            <div
              style={{
                float: "right",
                alignItems: "center",
                marginTop: "22px",
                marginLeft: "10px",
              }}
              // onClick={handleClick}
            >
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleClickOpenAdd}
                style={{ background: "#673595" }}
              >
                add
              </Button>
            </div>

            <div
              style={{
                float: "right",
                alignItems: "center",
                marginTop: "22px",
              }}
              onClick={handleClick}
            >
              <Button
                variant="contained"
                startIcon={<FilterAltIcon />}
                style={{ background: "#673595" }}
              >
                filter
              </Button>
            </div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                style: {
                  width: "105px",
                  transform: "translateX(-1%) translateY(1%)",
                  boxShadow:
                    "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  setStatusApiCall("all");
                }}
              >
                All
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setStatusApiCall("active");
                }}
              >
                Active
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setStatusApiCall("inactive");
                }}
              >
                Inactive
              </MenuItem>
            </Menu>

            <DataTableExtensions
              {...dataTable}
              export={false}
              filterPlaceholder={`Search`}
            >
              <DataTable
                columns={columns}
                data={data}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="45vh"
                selectableRowsHighlight
                highlightOnHover
                subHeader
                subHeaderAlign="left"
                paginationPerPage={10}
                conditionalRowStyles={conditionalRowStyles}
                // onRowClicked={handleOpenLeads}
                customStyles={customStyles}
              />
            </DataTableExtensions>
          </Grid>
        </Grid>
      </div>
      {openeditModal && (
        <EditAgencyModal
          onOpenEditModal={onOpenEditModal}
          onCloseEditModal={onCloseEditModal}
          open={openeditModal}
          setOpen={setOpenEditModal}
          data={editData}
          updatedData={updatedData}
          setUpdatedData={setUpdatedData}
        />
      )}

      {openAddModal && (
        <AddAgencyModal
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={openAddModal}
          handleClickNotSnkAdd={handleClickNotSnkAdd}
          handleClickNotSnkAddErr={handleClickNotSnkAddErr}
          handleClose={handleCloseAdd}
          updatedData={updatedData}
          setUpdatedData={setUpdatedData}
        />
      )}

      {showAgencyProjects && (
        <AddAgencyProjectsModal
          data={rowData}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={showAgencyProjects}
          handleClose={handleCloseProjects}
        />
      )}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openNotSnkAdd}
        autoHideDuration={3000}
        onClose={handleCloseNotSnkAdd}
      >
        <Alert
          onClose={handleCloseNotSnkAdd}
          severity="success"
          sx={{ width: "100%" }}
        >
          Added Data to table Successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openNotSnkAddErr}
        autoHideDuration={3000}
        onClose={handleCloseNotSnkAddErr}
      >
        <Alert
          onClose={handleCloseNotSnkAddErr}
          severity="error"
          sx={{ width: "100%" }}
        >
          Data Already Exists!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Agencies;
