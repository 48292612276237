
import "./App.css";
import RouterComponent from "./components/routes/RouterComponent";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();
  // useEffect(() => {
  //   navigate("/login");
  // }, []);

  useEffect(()=>{
    let ct = localStorage.getItem('count')
    if(ct != 1){
      localStorage.removeItem('token')
    }
  },[])


  return (
    <div className="App">
      <RouterComponent />
    </div>
  );
}

export default App;
