import { Button, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { getotp } from "../../utils";

import qc from "../../assets/Images/qc.svg";
import "../../css/Login.css";
import { Navigate, useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import NavbarInside from "../navbar/NavbarInside";
import Navbar from "../navbar/Navbar";

const Login = () => {
  const navigate = useNavigate();
  const [data, SetData] = useState({});
  const [err, setErr] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(data);
    setErr(false);
    axios
      .post(getotp, data)
      .then((res) => {
        console.log(res, "checkres");

        navigate("/otp", { state: { data } });
      })
      .catch((err) => {
        console.log(err.message);
        setErr(true);
      });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    SetData({ ...data, [name]: value });
  };
  return (
    <div>
      <Navbar />
      <Grid container justifyContent={"center"} className="mainGridLogin">
        <Grid item xs={12} md={6}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              direction="column"
              justifyContent={"center"}
            >
              <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                <img src={qc} alt="qc" class="mainImagedim" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h5" gutterBottom>
                  Quality Check Dashboard
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  className="mobileNumberHeading"
                >
                  Mobile Number
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <label id="mbNumber">
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Enter the registered company number
                      </Typography>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      className="mbnumberTextfield"
                      type="number"
                      inputProps={{
                        inputmode: "numeric",
                        pattern: "[0-9]*",
                      }}
                      // fullWidth
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      error={err}
                      helperText={err ? "Unauthorized access" : ""}
                      name="mobile_number"
                      id="mbNumber"
                      size="small"
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  type="submit"
                  variant="contained"
                  className="buttonLogin"
                >
                  Get Otp
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
