import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { dataToken } from "../config/token";
import { updateQcagent } from "../utils";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

//snackbar alter for displaying message
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditQcAgentModal = ({
  open,
  setOpen,
  onOpenEditModal,
  onCloseEditModal,
  data,
  updatedData,
  setUpdatedData,
  handleClickNotSnkAdd,
  handleClickNotSnkAddErr,
}) => {
  console.log(data, "datadatadata");

  let { full_name, email_id, number, status, type } = data;

  const [maxWidth, setMaxWidth] = React.useState("md");
  const [dataChanged, setDataChanged] = React.useState(false);
  const initData = {
    full_name,
    email_id,
    number,
    status,
    type,
  };
  const [formData, setFormData] = React.useState(initData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (
      JSON.stringify(initData) ===
      JSON.stringify({ ...formData, [name]: value })
    ) {
      setDataChanged(false);
    } else {
      setDataChanged(true);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData, "formdata");
    formData.id = data.id;
    // formData.user_type = formData.user_type == "qc" ? "" : formData.user_type;

    // handleClickSnackbar();

    setTimeout(() => {
      axios
        .put(updateQcagent, formData, dataToken)
        .then((res) => {
          console.log(res);
          onCloseEditModal();
          handleClickNotSnkAdd();

          setUpdatedData(!updatedData);
        })
        .catch((err) => {console.log(err)
        handleClickNotSnkAddErr()
        });
    }, 1000);
  };

  //snackbar
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleClickSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <>
      {" "}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          DataUpdated SuccessFully!
        </Alert>
      </Snackbar>
      <div>
        <Dialog
          fullWidth={true}
          maxWidth={maxWidth}
          open={open}
          onClose={onCloseEditModal}
        >
          <form onSubmit={handleSubmit}>
            <DialogTitle style={{ color: "#663595" }}>
              Edit Qc Agent Data
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid container direction={"column"} spacing={1}>
                    <Grid item>
                      <label id="fullname">Full Name</label>
                    </Grid>
                    <Grid item>
                      <TextField
                        id="fullname"
                        value={formData.full_name}
                        name="full_name"
                        onChange={handleChange}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container direction={"column"} spacing={1}>
                    <Grid item>
                      <label id="emailid">Email ID</label>
                    </Grid>
                    <Grid item>
                      <TextField
                        id="emailid"
                        fullWidth
                        value={formData.email_id}
                        name="email_id"
                        type="email"
                        // onChange={handleChange}
                        InputProps={{
                          readOnly: true,
                          disabled:true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container direction={"column"} spacing={1}>
                    <Grid item>
                      <label id="mobilenumber">Mobile Number</label>
                    </Grid>
                    <Grid item>
                      <TextField
                      type='number'
                        id="mobilenumber"
                        fullWidth
                        value={formData.number}
                        name="number"
                        // onChange={handleChange}
                        InputProps={{
                          readOnly: true,
                          disabled:true,
                        }}
                        
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container direction={"column"} spacing={1}>
                    <Grid item>
                      <label id="statius">Status</label>
                    </Grid>
                    <Grid item>
                      <TextField
                        id="statius"
                        select
                        fullWidth
                        defaultValue={formData.status}
                        // value={formData.status}
                        name="status"
                        onChange={handleChange}
                      >
                        <MenuItem value="active">Active</MenuItem>
                        <MenuItem value="inactive">Inactive</MenuItem>
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  {localStorage.getItem("type") == "agency" ? (
                    ""
                  ) : (
                    <Grid container direction={"column"} spacing={1}>
                      <Grid item>
                        <label id="user_type">User Type:</label>
                      </Grid>
                      <Grid item>
                        <TextField
                          id="user_type"
                          select
                          fullWidth
                          defaultValue={formData.type}
                          // value={formData.user_type}
                          name="type"
                          onChange={handleChange}
                        >
                          <MenuItem value="root">Root</MenuItem>
                          <MenuItem value="viewer">Viewer</MenuItem>
                          <MenuItem value="qc">qc</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                style={{
                  marginRight: "13px",
                  background: dataChanged ? "#673595" : "",
                }}
                variant="contained"
                disabled={!dataChanged}
              >
                Submit
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    </>
  );
};

export default EditQcAgentModal;
