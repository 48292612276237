import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    TextField,
  } from "@mui/material";
  import axios from "axios";
  import React from "react";
import { dataToken } from "../config/token";
import { addQcagent } from "../utils";

  
  const AddQcAgentModal = ({fullWidth,maxWidth,open,handleClose,handleClickNotSnkAdd,handleClickNotSnkAddErr,updatedData,setUpdatedData}) => {
    const [addData,setAddData] = React.useState({})

    const handleSubmit = (e)=>{
        e.preventDefault()
      //  addData.user_type =  addData.user_type == 'qc'? '':addData.user_type
        console.log(addData,'addData')

        axios.post(addQcagent,addData,dataToken)
        .then(res =>{

            if(res.data.error){
                handleClickNotSnkAddErr()
               
                
            }else{
        handleClickNotSnkAdd()
        setUpdatedData(!updatedData)
            }
        handleClose()
    })
        .catch(err => console.log(err,'err'))
    }

    const handleChange = e =>{
        const {name,value} =e.target;
        setAddData({...addData,[name]:value})
    }
    return (
      <div>
        
        <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit}>
        <DialogTitle><Grid item style={{color:'#663595'}}>ADD QC Agent Data</Grid></DialogTitle>
        <DialogContent>
        <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Grid container direction={"column"} spacing={1}>
                  <Grid item>
                    <label id="fullname">Full Name</label>
                  </Grid>
                  <Grid item>
                    <TextField
                      id="fullname"
                        required
                      name="full_name"
                      onChange={handleChange}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container direction={"column"} spacing={1}>
                  <Grid item>
                    <label id="emailid">Email Id</label>
                  </Grid>
                  <Grid item>
                    <TextField
                    type='email'
                      id="emailid"
                      required
                    
                      name="email_id"
                      onChange={handleChange}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container direction={"column"} spacing={1}>
                  <Grid item>
                    <label id="mbno">Number</label>
                  </Grid>
                  <Grid item>
                    <TextField
                    type='number'
                      id="mbno"
                      required

                      name="number"
                      onChange={handleChange}
                      fullWidth={true}
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*'}}
                      onInput={e=>e.target.value = Math.max(0,parseInt(e.target.value).toString().slice(0,10))}
                    />
                  </Grid>
                </Grid>
              </Grid>


              <Grid item xs={12} md={6}>
               {localStorage.getItem('type') =='agency'?'': <Grid container direction={"column"} spacing={1}>
                  <Grid item>
                    <label id="ust">User Type:</label>
                  </Grid>
                  <Grid item>
                    <TextField
                    select
                      id="ust"
                      required
                    
                      name="type"
                      onChange={handleChange}
                      fullWidth={true}
                    >

                        <MenuItem value='root'>Root</MenuItem>
                        <MenuItem value='viewer'>Viewer</MenuItem>
                        <MenuItem value='qc'>Qc</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>}
              </Grid>
        </Grid>
        </DialogContent>
        <DialogActions>
          <Button type='submit'  variant='contained' style={{background:'#673595'}}>Submit</Button>
        </DialogActions>
        </form>
      </Dialog>
      
      </div>
    )
  }
  
  export default AddQcAgentModal