import axios from "axios";
import { Grid } from "feather-icons-react/build/IconComponents";
import React from "react";
import { getLeadStatus } from "../../../utils";
import "../../../css/dashboard.css";
import NavbarInside from "../../navbar/NavbarInside";
import { useLocation } from "react-router-dom";
import { StatisticComp } from "../../dashboard/Dashboard";

const Stats = () => {
  let location = useLocation();

  let uers = location?.state?.users;

  console.log(uers, "location123");
  const [threecardData, setThreeCardData] = React.useState({});
  const [loading, setIsLoading] = React.useState(false);
  const [err, setIsErr] = React.useState(false);
  React.useEffect(() => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const dataTokenloc = {
      headers: {
        "x-access-token": token,
      },
    };
    axios
      .get(getLeadStatus, dataTokenloc)
      .then((res) => {
        console.log(res, "resthreecard");
        setThreeCardData(res?.data?.status);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsErr(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div>
      <NavbarInside users={uers} />
      <StatisticComp threecardData={threecardData} />
    </div>
  );
};

export default Stats;
