import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import "./Tableone.css";
import DataTableExtensions from "react-data-table-component-extensions";
import axios from "axios";
import { getFollowupTable, getLeadTable, lead_data } from "../../utils";
import { dataToken } from "../../config/token";
import { Box, CircularProgress, Grid } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import LeadIdDetails from "../leadidDetails/LeadIdDetails";
import { type } from "@testing-library/user-event/dist/type";
import { useNavigate } from "react-router-dom";
import "react-data-table-component-extensions/dist/index.css";

const columns = [
  {
    name: "lead Id:",
    width: "120px",
    center: true,
    cell: (d) => d.lead_id,

    style: {
      paddingLeft: "30px",
    },
  },
  {
    name: "sow Id",
    selector: (row) => row.sow_id,
    center: true,
    width: "300px",
  },
  {
    name: "Project",
    selector: (row) => row.ref_table_name,

    center: true,
  },
  {
    name: "fse",
    selector: (row) => row.fse,
    center: true,
  },
];

const customStyles = {
  rows: {
    style: {
      minHeight: "50px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "30px", // override the cell padding for head cells
      // paddingRight: "8px",
    },
  },
  // cells: {
  //   style: {
  //     paddingLeft: "8px", // override the cell padding for data cells
  //     paddingRight: "8px",
  //   },
  // },
};

const conditionalRowStyles = [
  {
    when: (row) => Number(row.id) % 2 === 0,
    style: {
      background: "#f7f7f7",
      border: "none",
      color: "black",
      cursor: "pointer",
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  {
    when: (row) => Number(row.id) % 2 !== 0,
    style: {
      background: "#ffffff",
      border: "none",
      color: "black",
      cursor: "pointer",
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
];
const Tableone = ({ calltableOne, setCalltableOne, dataval }) => {
  const [data, setData] = React.useState([]);
  const [loading, setIsLoading] = React.useState(false);
  const [err, setIsErr] = React.useState(false);
  const [leadData, setLeadData] = React.useState([]);
  const navigate = useNavigate();

  console.log(data, 766767);
  const handleOpenLeads = (d) => {
    console.log(d, d.ref_table_name.split("_").join(" "), "chdcking");
    localStorage.setItem("project", d.ref_table_name.split("_").join(" "));
    localStorage.setItem("leadIdl", d.lead_id);
    let api = lead_data + d.qc_table_data + "&lead_id=" + d.lead_id;

    const token = localStorage.getItem("token");
    const dataTokenloc = {
      headers: {
        "x-access-token": token,
      },
    };
    axios
      .get(api, dataTokenloc)
      .then((res) => {
        // console.log(res);
        let ot = res.data;
        console.log(ot, "ot");
        navigate("/lead-details", {
          state: {
            ot,
            projectId: d.project_id,
            leadId: d.lead_id,
            internalQcStatus: d.internal_qc_status,
            sow_id: d.sow_id,
          },
        });
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const dataTokenloc = {
      headers: {
        "x-access-token": token,
      },
    };

    let api = dataval == "0" ? getLeadTable : getFollowupTable;
    axios
      .get(api, dataTokenloc)
      .then((res) => {
        console.log(res.data, "sda");
        console.log(res.data.leads, "fasak123");

        let ot = res.data.leads.map((item, index) => ({ ...item, id: index }));
        setData(ot);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsErr(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [calltableOne]);
  const dataTable = {
    columns: columns,
    data: data,
  };

  return loading ? (
    <>
      {" "}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    </>
  ) : err ? (
    <>something went wrong</>
  ) : (
    <>
      <DataTableExtensions
        {...dataTable}
        export={false}
        print={false}
        filterPlaceholder={`Search`}
        style={{ paddingRight: "25px important" }}
      >
        <DataTable
          columns={columns}
          data={data}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="45vh"
          selectableRowsHighlight
          highlightOnHover
          subHeader
          subHeaderAlign="left"
          conditionalRowStyles={conditionalRowStyles}
          onRowClicked={handleOpenLeads}
          customStyles={customStyles}
        />
      </DataTableExtensions>
    </>
  );
};

export default Tableone;
