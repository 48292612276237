const far_base = "https://farming-backend.taskmo.in";

const call_base = "https://app.backend.taskmo.co";

const getotp = `${far_base}/qc/getotp`;
const verifyotp = `${far_base}/qc/verifyotp`;
const getLeadTable = `${far_base}/qctoken/getLeadTable`;
const lead_request = `${far_base}/qctoken/lead_request`;
const lead_data = `${far_base}/qctoken/table_list?`;
const getQcreasons = `${far_base}/qctoken/getQcreasons`;
const getUserinfo = `${far_base}/qctoken/getuserinfo`;
const qcSubmit = `${far_base}/qctoken/qcSubmit`;
const qcActionlog = `${far_base}/qctoken/qcActionlog`;
const getQcagents = `${far_base}/qctoken/getQcagents`;
const updateQcagent = `${far_base}/qctoken/updateQcagent`;

const addQcagent = `${far_base}/qctoken/addQcagent`;
const getLeadStatus = `${far_base}/qctoken/getLeadStatus`;

const getLeadSearch = `${far_base}/qctoken/getLeadSearch`;

const getAgencies = `${far_base}/qctoken/getAgencies`;

const getSowlist = `${far_base}/qctoken/getSowlist`;

const addSowtoagency = `${far_base}/qctoken/addSowtoagency`;

const removeSowtoagency = `${far_base}/qctoken/removeSowtoagency`;

const getLanglist = `${far_base}/qctoken/getLanglist`;

const addLangtoqc = `${far_base}/qctoken/addLangtoqc`;

const removelangtoqc = `${far_base}/qctoken/removelangtoqc`;

const qc_image_script = `${far_base}/qctoken/qc_image_script`;

const qc_script = `${far_base}/qctoken/qc_script`;

const getFollowupTable = `${far_base}/qctoken/getFollowupTable`;

const clicktocall = `${call_base}/qc/clicktocall`;

export {
  getotp,
  verifyotp,
  getLeadTable,
  lead_request,
  lead_data,
  getQcreasons,
  getUserinfo,
  qcSubmit,
  qcActionlog,
  getQcagents,
  updateQcagent,
  addQcagent,
  getLeadStatus,
  getLeadSearch,
  getAgencies,
  getSowlist,
  addSowtoagency,
  removeSowtoagency,
  getLanglist,
  addLangtoqc,
  removelangtoqc,
  qc_image_script,
  qc_script,
  getFollowupTable,
  clicktocall,
};
